import { Typography } from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import React from "react";

const MobileNav = ({ onNavClick, onContactClick }) => {
  const location = useLocation();
  const navItems = [
    {
      title: "HOME",
      to: "/",
    },
    {
      title: "SERVICES",
      to: "/services",
    },
    {
      title: "COMMUNITY",
      to: "/community",
    },
    {
      title: "ABOUT US",
      to: "/about",
    },
    {
      title: "CONTACT US",
      to: "/contact",
    },
  ];

  return (
    <nav
      aria-label="main mailbox folders"
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0,0,0,.71)",
        position: "fixed",
        zIndex: 99,
      }}
    >
      <List style={{ padding: 0 }}>
        {navItems.map((navItem) => (
          <ListItem
            component={Link}
            to={navItem.to}
            key={navItem.title}
            disablePadding
            style={{
              backgroundColor: "#1b1b1b",
              color: location.pathname === navItem.to ? "white" : "#a0a0a0",
            }}
          >
            <ListItemButton onClick={onNavClick}>
              <ListItemText
                style={{ textAlign: "center", padding: 8 }}
                primary={
                  <Typography
                    variant="h6"
                    component="div"
                    style={{ fontWeight: 300 }}
                  >
                    {navItem.title}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem
          key="contact"
          disablePadding
          style={{
            backgroundColor: "#1b1b1b",
            color: "#a0a0a0",
          }}
        ></ListItem>
      </List>
    </nav>
  );
};

export default MobileNav;
