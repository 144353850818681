import React, { useState, useEffect } from "react";
import { useMediaQuery, IconButton, useTheme } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles } from "@material-ui/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import MobileNav from "./MobileNav";

import logo from "../../assets/logo.svg";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "1rem",
  },
  customizeToolbar: {
    disableGutters: true,
    minHeight: "82px",
    width: "100%",
    display: "flex",
    alignContent: "space-between",
  },
  buttonpad: {
    paddingLeft: "4rem",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  logo: {
    height: "2.5rem",
    //  paddingLeft: "4.2rem",
  },
  logoContainer: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tabContainer: {
    marginLeft: "Auto",
  },

  tab: {
    minWidth: 10,
    letterSpacing: ".75px",
    //color:"#ff00eb", changes font color keeping white for now
  },
  styledIndicator: {
    backgroundColor: "#01aee3",
  },
}));

const Header = (props, { executeScroll }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(isMobile);
  const handleChange = (e, value) => {
    setValue(value);
  };

  const handleMobileNavClick = () => {
    setIsMobileNavOpen(false);
  };
  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleMobileNavContactClick = () => {
    setIsMobileNavOpen(false);
    executeScroll();
  };
  useEffect(() => {
    if (window.location.pathname === "/" && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === "/services" && value !== 1) {
      setValue(1);
    } else if (window.location.pathname === "/community" && value !== 2) {
      setValue(2);
    } else if (window.location.pathname === "/about" && value !== 3) {
      setValue(3);
    } else if (window.location.pathname === "/contact" && value !== 4) {
      setValue(4);
    }
  }, [value]);

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" color="secondary" style={{ width: "100%" }}>
          {/* default position is fixed out of the box for AppBar adding note.
          NOTE** you can change the default colot by changing from Primary to secondary
          look at Theme.js file*/}
          <Typography variant="h3">
            <Toolbar className={classes.customizeToolbar}>
              <div className={classes.buttonpad}>
                <Button
                  component={Link}
                  className={classes.logoContainer}
                  disableRipple
                  to="/"
                >
                  <img
                    style={{ fill: "white" }}
                    src={logo}
                    className={classes.logo}
                    onClick={() => {
                      setValue(0);
                    }}
                    alt="Company logo"
                  />
                </Button>
              </div>
              {isMobile && (
                <Box
                  style={{ right: 70, position: "absolute" }}
                  sx={{ flexGrow: 1, display: { sm: "flex", md: "none" } }}
                >
                  <IconButton
                    style={{ marginLeft: "auto" }}
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={toggleMobileNav}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              )}
              {!isMobile && (
                <Box
                  sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                  className={classes.boxContainer}
                  style={{ right: 100, position: "absolute" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.tabContainer}
                    variant="scrollable"
                    classes={{ indicator: classes.styledIndicator }}
                  >
                    <Tab
                      className={classes.tab}
                      component={Link}
                      to="/"
                      label="Home"
                    />
                    <Tab
                      className={classes.tab}
                      component={Link}
                      label="Services"
                      to="/services"
                    />
                    <Tab
                      className={classes.tab}
                      component={Link}
                      to="/community"
                      label="Community"
                    />
                    <Tab
                      className={classes.tab}
                      component={Link}
                      label="About Us"
                      to="about"
                    />
                    <Tab
                      className={classes.tab}
                      component={Link}
                      label="Contact Us"
                      to="contact"
                    />
                  </Tabs>
                </Box>
              )}
            </Toolbar>
          </Typography>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin}></div>
      {isMobileNavOpen && isMobile && (
        <MobileNav
          onNavClick={handleMobileNavClick}
          onContactClick={handleMobileNavContactClick}
        />
      )}
    </React.Fragment>
  );
};

export default Header;
