import React from "react";
import { makeStyles } from "@material-ui/styles";
import Logo from "../../assets/images/contact.jpg";
import { Card, CardMedia, Grid } from "@material-ui/core";
import ContactMailSharpIcon from "@material-ui/icons/ContactMailSharp";

const Contact = () => {
  const useStyles = makeStyles((theme) => ({
    //Hero Banner CSS
    media: {
      height: "500px",
      backgroundPosition: "inherit",
    },
    overlay: {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "#00000085",
    },
    text: {
      color: "white",
      paddingTop: "6em",
      fontFamily: "sans-serif",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
    },
    card: {
      position: "relative",
      border: "none",
      boxShadow: "none",
    },
    bottomLine: {
      fontSize: "18px",
      marginTop: "20px",
    },
    topLine: {
      fontSize: "33px",
      height: ".5rem",
      paddingTop: "50px",
    },
    // Contact info CSS
    page: {
      //backgroundColor: "#0e2c58",
      backgroundColor: "#375566",
      minHeight: "60vh",
    },
    header: {
      fontSize: "3rem",
      justifyContent: "center",
      color: "white",
      paddingTop: "4rem",
      fontFamily: "sans-serif",
    },
    horizontalLine: {
      width: "30rem",
    },
    officeLocations: {
      color: "white",
      fontFamily: "sans-serif",
    },
    soonText: {
      color: "white",
      display: "flex",
      justifyContent: "center",
      fontFamily: "sans-serif",
      paddingTop: "2rem",
    },
    horizontalLineTwo: {
      marginTop: "7rem",
      width: "30rem",
    },
    email: {
      color: "white",
      fontFamily: "sans-serif",
    },
    emailIcon: {
      color: "white",
      fontSize: "70px",
      //paddingTop: "5px",
      paddingLeft: "30px",
    },
    gridContainer: {
      textAlign: "center",
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <div>
        <Card className={classes.card}>
          <CardMedia image={Logo} className={classes.media}>
            <div className={classes.overlay}>
              <div className={classes.text}>
                <h2 className={classes.topLine}>Contact Us</h2>
                <h5 className={classes.bottomLine}>
                  Come start a new project with our NebuCorp team
                </h5>
              </div>
            </div>
          </CardMedia>
        </Card>

        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={12} md={12}>
            <Card style={{ backgroundColor: "#152b56" }}>
              <div className={classes.page}>
                <div className={classes.allText}>
                  <header className={classes.header}>NebuCorp</header>
                  <hr className={classes.horizontalLine}></hr>
                  <h1 className={classes.officeLocations}>Office Locations</h1>
                  <h2 className={classes.soonText}>
                    8751 Collin McKinney Pkwy STE 1102 #519
                    <br></br>
                    McKinney, TX 75070
                  </h2>
                  <h2 className={classes.soonText}>PH: (469) 296-8340</h2>
                  <hr className={classes.horizontalLineTwo}></hr>
                  <h2 className={classes.email}>
                    <p>Contact us with any questions!</p>
                    Email: information@nebucorp.com
                    <ContactMailSharpIcon className={classes.emailIcon} />
                  </h2>
                </div>
                <div className={classes.iconContainer}></div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default Contact;
