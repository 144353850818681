import React from "react";
import { makeStyles } from "@material-ui/styles";
import Working from "../../assets/images/working.jpg";
import { CardMedia, Grid } from "@material-ui/core";
import Faq from "react-faq-component";
import { height } from "@mui/system";

const data = {
  title: "Frequently Asked Questions",
  rows: [
    {
      title: "Is Nebucorp an expensive Firm?",
      content: `Not at all, we strive to get you the best quality of software and engineering possible while keeping our
        prices super competitive. We can do a whole website for as low as $500.`,
    },
    {
      title: "Where is Nebucorp located?",
      content: `We are in the McKinney area in Texas. We strive to be the best consulting company out there. If you find a better deal let us know. We can try to match and even beat it!`,
    },
    {
      title: "Can you set up my company Email?",
      content:
        "Of course! We do all services and we can create a company email for anyone. We take care of finding you a compatiple email and we'll create and host your MX record.",
    },
    {
      title: "I just want a simple website nothing fancy. Can you do that?",
      content: `Yes, if you just want a website built with no hosting that is absolutely possible and one of our cheapest options.`,
    },
    {
      title: "Can you take care of hosting for me?",
      content: `Yes, we do it all. So we can take care of hosting for you. We do a monthly plan where we take care of hosting your website. Just contact us to get started!`,
    },
  ],
};

const styles = {
  // bgColor: 'white',

  titleTextColor: "#1a3352",
  rowTitleColor: "#1a33526457",
  // rowContentColor: 'grey',
  // arrowColor: "red",
};
const config = {
  animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};
const About = () => {
  const useStyles = makeStyles((theme) => ({
    media: {
      height: "300px",
      backgroundPosition: "inherit",
    },
    overlay: {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "#00000085",
    },
    paragraph: {
      fontSize: "20px",
      lineHeight: "2rem",
    },
    gridParent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    card: {
      position: "relative",
      border: "none",
      boxShadow: "none",
    },
    gridContainer: {
      paddingTop: "3rem",
      paddingBottom: "2rem",
      paddingLeft: "5rem",
      paddingRight: "3rem",
    },
    whatwedo: {
      display: "flex",
      height: "38rem",
      width: "43rem",
    },
    text: {
      color: "white",
      paddingTop: "6em",
      fontFamily: "sans-serif",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
    },
    topLine: {
      fontSize: "30px",
      height: ".5rem",
      paddingTop: "50px",
    },
    aboutText: {},
    bottomLine: {},
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        className={classes.gridContainer}
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} md={6} className={classes.gridParent}>
          <div>
            <header className={classes.header}>
              Software Company - McKinney TX
            </header>
            <p className={classes.paragraph}>
              We are a group of dedicated software engineers with over 20 years of experience. We are ready to help you tackle your next project!
            </p>
            <p className={classes.paragraph}>
              Our agile development process typically runs in two-week
              increments (Sprints) that result in working software that is
              shared with you to illicit immediate feedback. Sprints are
              coordinated using a release plan that is created with your
              organization’s stakeholders and our team’s development expertise.
              Regular check-points and demonstrations provide visible progress
              towards business needs, receive user feedback, and obtain
              empirical data to support development planning and risk
              management. Quality is integrated into and an expectation of each
              increment of development. Software is only presented for
              demonstrated after completion of continuous and automated testing
              within each Sprint. Demos are the team’s way of endorsing the work
              that is presented as functional, thoroughly tested, and valuable.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridParent}>
          <CardMedia image={Working} className={classes.whatwedo} />
        </Grid>
        <Faq data={data} styles={styles} config={config} />
      </Grid>
    </React.Fragment>
  );
};
export default About;
