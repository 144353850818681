import React from "react";
import { makeStyles } from "@material-ui/styles";
import Logo from "../../assets/images/services.jpg";
import Agile from "../../assets/images/agile2.svg";
import UX from "../../assets/images/ux.svg";
import DevOps from "../../assets/images/devops.svg";
import Enterprise from "../../assets/images/enterprise.svg";
import systemIntegration from "../../assets/images/systemIntegration.svg";
import security from "../../assets/images/security.svg";
import { Card, CardMedia } from "@material-ui/core";
import "./Services.css";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Services = () => {
  const useStyles = makeStyles((theme) => ({
    media: {
      height: "300px",
    },
    whatwedo: {
      display: "flex",
      height: "30rem",
      width: "39rem",
    },
    systemIntegration: {
      display: "flex",
      height: "30rem",
      width: "39rem",
    },
    devOps: {
      display: "flex",
      height: "33rem",
      width: "43rem",
    },
    enterprise: {
      display: "flex",
      height: "33rem",
      width: "43rem",
    },
    ux: {
      display: "flex",
      height: "27rem",
      width: "41rem",
    },
    security: {
      display: "flex",
      height: "33rem",
      width: "43rem",
    },
    overlay: {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "#00000085",
    },
    text: {
      color: "white",
      paddingTop: "6em",
      fontFamily: "sans-serif",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
    },
    topLine: {
      fontSize: "30px",
      height: ".5rem",
      paddingTop: "50px",
    },
    agileSoftDev: {
      marginTop: "100px",
      height: "70rem",
      // paddingLeft: "10rem",
      border: "none",
      boxShadow: "none",
    },
    paragraph: {
      fontSize: "20px",
      lineHeight: "2rem",
    },
    card: {
      position: "relative",
      border: "none",
      boxShadow: "none",
    },
    header: {
      fontFamily: "sans-serif",
      fontSize: "35px",
      fontWeight: "bold",
    },
    sysIntegration: {
      border: "none",
      boxShadow: "none",
      backgroundColor: "grey",
      color: "grey",
    },
    userEx: {
      border: "none",
      boxShadow: "none",
    },
    cloudDevOps: {
      border: "none",
      boxShadow: "none",
    },
    infoSecurity: {
      border: "none",
      boxShadow: "none",
      paddingLeft: "0rem",
      paddingTop: "3rem",
    },
    gridParent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    gridContainer: {
      paddingTop: "3rem",
      paddingBottom: "2rem",
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
    enterpriseArchitecture: {
      border: "none",
      boxShadow: "none",
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  // {matches ? null :()}
  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardMedia image={Logo} className={classes.media} />
        <div className={classes.overlay}>
          <div className={classes.text}>
            <div className={classes.topLine}>
              <h2>Our Services</h2>
            </div>
          </div>
        </div>
      </Card>

      <Grid container spacing={0} className={classes.gridContainer}>
        {matches ? null : (
          <Grid item xs={12} md={6} className={classes.gridParent}>
            <CardMedia image={Agile} className={classes.whatwedo} />
          </Grid>
        )}

        <Grid item xs={12} md={6} className={classes.gridParent}>
          <div>
            <header className={classes.header}>
              Agile Software Development
            </header>
            <p className={classes.paragraph}>
              We use agile practices because they deliver value quickly and
              establish the type of collaboration we want to have with clients.
              Short feedback loops, incremental evidence of progress, and
              efficiency are some of the reasons we look for opportunities to
              apply agile development in new settings, applications, and
              environments. We partner with clients to develop a shared concept
              of what is desired, feasible, and practical to create an
              engagement model suited to the business needs and available
              resources. Every situation and organization is different; we want
              to help you learn about, evaluate, and test how to make agile
              ideas work for you. Agile is immediately attractive because of the
              advertised advantages of the end result, but we want to also draw
              attention to benefits to individuals when they are able move away
              from the pressure and anxiety of traditional project models.
            </p>
            <p className={classes.paragraph}>
              Our agile development process typically runs in two-week
              increments (Sprints) that result in working software that is
              shared with you to illicit immediate feedback. Sprints are
              coordinated using a release plan that is created with your
              organization’s stakeholders and our team’s development expertise.
              Regular check-points and demonstrations provide visible progress
              towards business needs, receive user feedback, and obtain
              empirical data to support development planning and risk
              management. Quality is integrated into and an expectation of each
              increment of development. Software is only presented for
              demonstrated after completion of continuous and automated testing
              within each Sprint. Demos are the team’s way of endorsing the work
              that is presented as functional, thoroughly tested, and valuable.
            </p>

            <p className={classes.paragraph}>
              We bring experience in the latest technology platforms to deliver
              products that provide the features you need now and in the
              foreseeable future. We adjust the technology to your standards and
              staff skill sets. Some examples of technology platforms we value
              as scalable, secure, and quick to develop include:
            </p>
            <ul className={classes.paragraph}>
              <li>Front End: React.js Angular.js</li>
              <li>Back End: Node.js, Dropwizard (Java) and Ruby</li>
              <li>Data: PostgreSQL, MySQL, Cassandra and Elasticsearch</li>
            </ul>
            <p className={classes.paragraph}>
              These technologies allow for deployment in cloud-based and
              on-premise infrastructure environments. In addition to software
              development, we can assist your organization’s transition to cloud
              infrastructure and services to accelerate development and reduce
              cost.
            </p>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div>
            <header className={classes.header}>System Integration</header>

            <p className={classes.paragraph}>
              Iterative development conducted in two-week cycles strengthens
              system integration projects with demonstrated progress verified by
              working software. Regular and focused feedback from users and
              stakeholders ensures the work efforts align with expectations and
              needs. It is also a way to ensure long term alignment in vision,
              mission, expectations, and execution. Agile-based development also
              provides a number of techniques for risk management including use
              of prototypes, proof of concepts, and other low-effort high-impact
              efforts to validate identified risks and develop mitigations.
            </p>
            <p className={classes.paragraph}>
              Our approach is to work with clients to develop a set of software
              releases that represent logical segments of business
              functionality. We collaborate to produce a roadmap and release
              plan that communicate the strategy to deliver a complete system
              over a series of releases. The release plan includes management of
              cross-team dependencies; our team has experience using agile
              development while managing dependencies in coordination with other
              teams that may be using a different development approach, such as
              waterfall. It is frequently the case that our client’s project
              management offices utilize the practices of the Project Management
              Institute. In addition to experience with system development, we
              also bring a set of practices to integrate agile development with
              your standard practices. This results in not just clear metrics
              and reports but also progress that is valuable to user and
              demonstrable to stakeholders.
            </p>
            <p className={classes.paragraph}>
              We understand that agile may not automatically align with your
              organization practices or regulatory requirements and can also
              deliver your project using an iterative methodology. Iterative
              development can also be a good choice for projects with definitive
              scope, like technical upgrades, of if the there is a clear linear
              progression for the implementation of the software package(s).
              Iterative development is also a way to gradually implement agile
              practices in your organization using iterations over months
              instead of weeks. Our iterative approach is based on the Project
              Management Body of Knowledge (PMBOK) and supplemented with
              practices and tools we have developed for managing system
              integration projects.
            </p>
          </div>
        </Grid>

        {matches ? null : (
          <Grid item xs={12} md={6} className={classes.gridParent}>
            <CardMedia
              image={systemIntegration}
              className={classes.systemIntegration}
            />
          </Grid>
        )}

        {matches ? null : (
          <Grid item xs={12} md={6} className={classes.gridParent}>
            <CardMedia image={UX} className={classes.ux} />
          </Grid>
        )}

        <Grid item xs={12} md={6} className={classes.gridParent}>
          <div>
            <header className={classes.header}>
              User Experience/User Interface Design
            </header>

            <p className={classes.paragraph}>
              User Experience/User Interface (UX/UI) is a newer field of
              practice based on an age-old business principle: The customer is
              always right. Our job is to identify the ways we can help you
              achieve your objectives. We look to provide options that are
              supported by real user data, so decisions are made in alignment
              with the organization’s vision and values. In this way, the
              engagement may start as part of a transaction and it grows into a
              partnership.
            </p>
            <p className={classes.paragraph}>
              You may be wondering why we define our practice as UX/UI instead
              of UI/UX, especially if you are already familiar with the latter.
              It is just one sign of NebuCorp’s conscious commitment to
              research-based user experience above slick designs and prototypes
              that sell well, look great, and have no connection to your real
              users. UX is always important and it begins in the earliest stages
              of any project whenever possible. Even during planning stages,
              UX/UI can begin early user, client, and stakeholder engagements to
              establish those relationships and minimize project delays. With
              some creativity, early and ongoing research can be done with
              minimal impact to the users. We invite users into a collaborative
              experience that focuses on what they want and need and why. They
              are valued not just for their interaction with the system, but as
              invaluable members of the organization’s eco system.
            </p>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div>
            <header className={classes.header}>Enterprise Architecture</header>
            <p className={classes.paragraph}>
              Enterprise Architecture (EA) and its subdisciplines provide the
              foundation for strategic planning and to develop roadmaps to guide
              implementation efforts. Having an enterprise architecture based on
              your organization’s business and technical goals supports informed
              investments and management of your IT portfolio. NebuCorp differs
              from other firms that provide EA services in that we engage
              architects with real-world project implementation to ensure the
              architecture we develop is actionable and quickly provides return
              on investment. We use our experience with legacy system
              modernization and integration patterns to develop transition
              architectures that provide incremental benefits and reduce the
              risk of your organization’s transformation initiatives.
            </p>
            <p className={classes.paragraph}>
              Our approach to actionable enterprise architecture, or as we like
              to think of it, Action Architecture, is focused on your
              organization’s to-be state, and a comprehensive roadmap and set of
              supporting plans to implement your new Action Architecture. The
              to-be state answers “What do we want and need?” and transition
              architectures and implementation plans answer, “How will we do
              it?”. In addition to architecture designs, we create playbooks to
              provide guidance about how to implement new architecture patterns
              and platforms because they a helpful for knowledge transfer and
              are revisited for future IT transformation. We work with your
              program management to support planning and identify projects that
              fund implementation of enterprise shared services and data across
              systems. Use of enterprise shared services reduces implementation
              time and cost. We analyze your existing business processes,
              existing IT systems, and project to create transition
              architectures that address dependencies, establish, and leverage
              shared services.
            </p>
            <p className={classes.paragraph}>
              We use the Open Group Enterprise Architecture Framework (TOGAF)
              Architecture Development Methodology adapted to iterative based
              project implementations. Our iterative methodology creates your
              architecture incrementally to initiate projects in a more rapid
              yet risk reducing model than traditional waterfall-based EA
              approaches. We first create a set of guiding principles and
              overall roadmap, then we create transition architectures that
              incorporate the following domains:
            </p>
            <ul className={classes.paragraph}>
              <li>Business Architecture</li>
              <li>Information Architecture</li>
              <li>Security Architecture</li>
              <li>Technology Architecture</li>
            </ul>
            <p className={classes.paragraph}>
              Often, realizing your objectives through EA requires
              organizational change. This can include changes with your existing
              EA organization, program management office and technical teams.
              NebuCorp provides supplemental Organizational Change Management
              (OCM) services to prepare your teams to execute the defined EA.
              Our EA and OCM experts engage can assess your organization’s
              ability to provide governance, manage projects and implement new
              technologies. This is an injection of strategic action into the
              traditional EA engagement to create Action Architecture. We then
              work with your leadership to develop commitments to prepare and
              manage organizational change. This includes implementation of new
              policies and procedures, training, and talent acquisition. Our
              team can also capture and refine metrics and factors to measure
              the efficacy of change management and EA outcomes.
            </p>
            <p className={classes.paragraph}>
              In our Health and Human Services practice, we provide specialized
              Action Architecture skills and working experience implementing
              systems designed through the Medicaid Information Technology
              Architecture 3.0 (MITA) guidelines. Our background with MITA
              creates an EA that not only complies with Centers for Medicare and
              Medicaid (CMS) funding requirements but also provides useful tools
              for your project implementation teams. We have hands-on expertise
              using MITA and related standards to design systems that promote
              component reuse and data sharing across organizations. We also
              specialize in defining security architectures from our experience
              implementing systems subject to the Health Insurance Portability
              and Accountability Act (HIPAA) security requirements. We develop
              security architectures to comply with regulatory requirements and
              to safeguard your system’s data in a manner that supports business
              operations. We are committed to helping clients learn about and
              comply with the required and recommended practice standards.
            </p>
          </div>
        </Grid>

        {matches ? null : (
          <Grid item xs={12} md={6} className={classes.gridParent}>
            <CardMedia image={Enterprise} className={classes.enterprise} />
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <Card className={classes.userEx}></Card>
        </Grid>

        {matches ? null : (
          <Grid item xs={12} md={6} className={classes.gridParent}>
            <CardMedia image={DevOps} className={classes.devOps} />
          </Grid>
        )}

        <Grid item xs={12} md={6} className={classes.gridParent}>
          <div>
            <header className={classes.header}>Cloud & DevOps</header>

            <p className={classes.paragraph}>
              Cloud has the potential to streamline operational overhead that
              typically taxes on-premise solutions. Our cloud services approach
              is customized to meet the specific needs of our clients and
              address the solution architecture and technical requirements
              associated with each project. We bring clients a practical
              approach to execute their cloud strategies from previous projects
              spanning cloud migrations, development of cloud-native
              applications, and implementation of Development and Operations
              (DevOps). The security of your environment is assured through use
              of Federal Risk and Authorization Management Program (FedRAMP)
              services and our background successfully implementing cloud-based
              projects in compliance with regulatory requirements, such as the
              Health Insurance Accountability and Portability Act (HIPAA) and
              environments containing Department of Defense Controlled
              Unclassified Information (CUI).
            </p>
            <p className={classes.paragraph}>
              We offer a full set of services to help clients maximize the
              benefits of cloud offerings including:
            </p>
            <ul className={classes.paragraph}>
              <li>
                Cloud Strategy and Planning: We engage your organization to
                analyze your application and project portfolio to identify
                opportunities to utilize cloud-based services. We evaluate your
                organization’s existing applications, new development efforts,
                and staff skillsets to determine opportunities to use cloud
                services to realize objectives and receive return on investment.
              </li>
              <li>
                Architecture: Using your application profiles along with
                operational and regulatory requirements we develop a cloud
                architecture that is customized for your organization. We
                identify the ideal cloud offerings such as Infrastructure as a
                Service (IaaS) or Platform as a Service (PaaS) compatible with
                your applications. We specialize in developing hybrid cloud
                deployments to integrate with your existing environment as a
                pathway to gradually migrate your portfolio to the cloud.
              </li>
              <li>
                Migration Services: In our system integration practice we plan
                and execute projects to migrate your existing applications to
                the cloud. We configure a cloud hosting environment that
                reflects your application technical and organization operational
                requirements. We provide a full set of services to not only
                migrate your application but also to assist your team with
                planning and executing the cut over to avoid impact to your
                operations.
              </li>
              <li>
                Cloud Native Development: If your efforts include new cloud
                native applications, we provide a full set of cloud and
                development services. Depending on your needs, we offer a
                selection of services for product management, development, and
                project management.
              </li>
              <li>
                Managed Services: Some organizations require time to develop
                their teams’ skills to support cloud-based environments. To
                accelerate adoption of cloud services we provide managed
                services for your cloud environment and, when needed,
                application support.
              </li>
            </ul>
            <p className={classes.paragraph}>
              Development and Operations (DevOps) accelerates your cloud and
              software development efforts with automation and organizational
              practices to reduce development, testing, and deployment time.
              Technologies such as containerization and cloud formation
              templates automate the infrastructure aspects of DevOps to reduce
              your initial cloud migration and ongoing deployments.
            </p>
            <p className={classes.paragraph}>
              Our DevOps and software development teams analyze your
              organization’s technology platforms to design and implement a full
              Continuous Integration and Continuous Deployment (CI/CD) pipeline
              to automate build, test, deploy, and validate applications. DevOps
              Security (DevSecOps) also improves application and environment
              security. We recommend integrating static and dynamic code
              analysis tools along with vulnerability scanning tools to
              proactively enhance security before issues ever occur. Our
              information security experts can work with your staff to determine
              a DevSecOps approach that addresses threats and regulatory
              requirements.
            </p>
            <p className={classes.paragraph}>
              Technology is just the start of DevOps. Cultural change is
              required to fully realize the benefits of DevOps. For example,
              typical silos between development and infrastructure need to be
              resolved to create a culture in which information technology staff
              collaborate to create a cohesive build, deploy and test pipeline.
              In addition to deployment services, our DevOps experts and coaches
              can develop an organization change management plan to unify your
              teams across technology pillars.
            </p>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div>
            <header className={classes.header}>Information Security</header>
            <p className={classes.paragraph}>
              Interconnectivity and constituent facing services improve the
              efficiency of service delivery and unfortunately, also increases
              the number of types of information security threats faced by
              government entities. Safeguarding sensitive data and maintaining
              the trust of constituents requires a comprehensive information
              security program and continuous mitigation of threats. Through our
              focus on government clients, we have developed expertise to help
              clients maintain an effective information security program that is
              supportive of service delivery. We provide clients with services
              to comply with regulatory requirements including:
            </p>
            <ul className={classes.paragraph}>
              <li>
                Health Insuranec Portability and Accountability Act (HIPAA)
              </li>
              <li>Internal Revenue Service Publication 1075</li>
              <li>
                Defense Federal Acquisition Regulation Supplement (DFARS)
                252.204-7012
              </li>
              <li>Foreign Ownership, Control or Influence (FOCI)</li>
            </ul>
            <p className={classes.paragraph}>
              Comprehensive information security requires controls and practices
              within both cybersecurity and software assurance (SwA) domains. In
              our software development and DevOps practices we assist clients
              with developing security controls to secure their operations and
              SwA practices to mitigate vulnerabilities with your applications.
              We provide clients with a variety of services to help improve
              information security programs and implementation of security
              controls:
            </p>
            <ul className={classes.paragraph}>
              <li>
                <p>
                  Security Assessments: Our information security team engages
                  with your organization to identify regulatory requirements and
                  threats to your organization. We evaluate current practices
                  relative to risks to identify opportunities to fortify
                  security. Because we also implement systems, we can provide
                  your organization with not only a set of recommendations but a
                  clear and actionable path towards implementation.
                </p>
              </li>
              <li>
                <p>
                  Software Assurance Programs: The integrity, resiliency, and
                  availability of software is highly dependent on your
                  development practices. Our agile-based software development
                  efforts include SwA through peer reviews along with automated
                  tools for code analysis. We engage with your development team
                  to capture current practices and evaluate software against
                  standards like the MITRE Common Weakness Enumeration (CWE).
                  With this information, we develop supplemental development
                  practices to proactively address vulnerabilities. Our team can
                  also assist your organization with SwA-based automation to
                  help develop your DevSecOps program.
                </p>
              </li>
              <li>
                <p>
                  Cybersecurity for Cloud: Similar to our approach to SwA, our
                  team of cloud-based experts engages with your team to identify
                  ways to improve the cyber security of your cloud-based
                  environments. This includes evaluation of your cloud
                  architecture’s use of compartmentalization (e.g., Virtual
                  Private Cloud), access controls and real-time threat
                  mitigation technologies. We develop a to-be state architecture
                  that improves security along with an incremental
                  implementation plan. Our team can also assist your
                  organization with integrating vulnerability scanning tools
                  into your CI/CD automation to further improve security through
                  DevSecOps.
                </p>
              </li>
              <li>
                <p>
                  Penetration Testing: As another level of validation or to
                  satisfy regulatory requirements, we provide clients with
                  penetration testing services. Our team conducts surveillance
                  of your systems to identify vulnerabilities and conduct
                  simulated attacks on your systems. Depending on your
                  organization’s risks we can simulate a variety of technical
                  attack vectors and social engineering. Using the results of
                  penetration testing, we help your organization identify
                  mitigations and a program to develop ongoing mitigations.
                </p>
              </li>
            </ul>
          </div>
        </Grid>
        {matches ? null : (
          <Grid item xs={12} md={6} className={classes.gridParent}>
            <CardMedia image={security} className={classes.security} />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};
export default Services;
