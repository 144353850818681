import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Box, Card, CardMedia, Grid, Button } from "@material-ui/core";
import styles from "./Home.module.css";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import CachedIcon from "@material-ui/icons/Cached";
import GitHubIcon from "@material-ui/icons/GitHub";
import SecurityIcon from "@material-ui/icons/Security";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

import Logo from "../../assets/images/image3.jpg";
import background from "../../assets/images/circuitPrimary.svg";
import backgroundRight from "../../assets/images/circuitPrimaryRight.svg";
import websiteBuild from "../../assets/images/builtWebsite.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100px",
  },
  cardBorder: {
    border: "none",
    boxShadow: "none",
    maxWidth: "42rem",
    paddingLeft: "3rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
  },
  gridContainer: {
    //paddingLeft: "1rem",
    //paddingRight: "1rem",
    paddingTop: "50px",
    paddingBottom: "100px",
    width: "100% !important",
  },
  media: {
    height: "600px",
  },
  card: {
    position: "relative",
    border: "none",
    boxShadow: "none",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#041c4985", //#00000085 not bad color
  },
  topLine: {
    fontSize: "1.7rem",
    ["@media (max-width:500px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "37px",
    },
    height: ".5rem",
    paddingTop: "50px",
  },
  bottomLine: {
    fontSize: "2.8rem",
    ["@media (max-width:581px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "30px",
    },
  },
  text: {
    color: "white",
    paddingTop: "6em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },
  corporate: {
    backgroundColor: theme.palette.common.nebBlue,
    opacity: 0.95,
  },
  whiteFont: {
    color: "white",
  },
  builtWebsite: {
    height: "28rem",
    width: "100%",
    display: "flex",
    backgroundImage: `url(${background})`,
    justifyContent: "right",
  },
  builtWebsiteMobile: {
    ["@media (max-width:581px)"]: {
      width: "100%",
      paddingRight: "0rem !important",
    },
    paddingRight: "4rem !important",
  },
  cardBackground: {
    backgroundImage: `url(${backgroundRight})`,
  },
  spaceLetters: {
    letterSpacing: "1px",
  },
  marginY: {
    marginTop: "3.5rem",
  },
  websiteMobileDev: { paddingRight: "1rem" },
}));

// .overlay {
//       left: 0;
// right: 0;
// top: 0;
// width: 100%;
// height: 100%;
// z-index: 1;
//   }

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardMedia image={Logo} className={classes.media} />
        <div className={classes.overlay}>
          <div className={classes.text}>
            <h2 className={classes.topLine}>Software Made Easy</h2>
            <h1 className={classes.bottomLine}>
              Lets Get Your Project Started
            </h1>
          </div>
        </div>
      </Card>

      {/* Entrepreneur section */}

      <h1
        style={{ color: theme.palette.common.nebDarkBlue }}
        className={styles.centerText}
      >
        Small Business Services
      </h1>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid container className={classes.gridContainer}>
          {/* joseph todo */}
          <Grid item xs={12} md={6}>
            <div className={classes.builtWebsite}>
              <img
                className={classes.builtWebsiteMobile}
                src={websiteBuild}
                alt="website build logo"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.cardBackground}>
            <Card className={classes.cardBorder}>
              <div className={classes.websiteMobileDev}>
                <h1 className={classes.spaceLetters}>
                  Website and Mobile Web Development
                </h1>
                <p>
                  Let us help you get your store online. Our engineers will help
                  you build the site you want and keep you informed at every
                  step of the process. We take the headache out of site builders
                  like Wordpress. We'll host your site for you and create
                  business accounts with maintenance as low as $10 a month.
                </p>
                <div className={classes.marginY}>
                  <a
                    href="mailto:information@nebucorp.com"
                    className={styles.btnlink}
                  >
                    Get a quote today!
                  </a>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <div
              className={classes.marginY}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" color="primary">
                Need to see more?
              </Button>
            </div>
          </Grid>
          {/* joseph todo end */}
          {/* <Grid item xs={12} md={4}>
          <Card className={classes.cardBorder}>
            <div>
              <i></i>
              <h5 className={styles.headingSub}>
                {" "}
                <CachedIcon
                  style={{
                    color: "#01b4cb",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                />{" "}
                Software Development
              </h5>
            </div>
            <p className={styles.pagetextleft}>
              We use agile practices because they deliver value quickly and
              establish the type of collaboration we want to have with clients.
              Short feedback loops, incremental evidence of progress, and
              efficiency are some of the reasons we look for opportunities to
              apply agile development in new settings, applica...
            </p>

            <a
              href="/services#agile-software-development"
              className={styles.btnlink}
            >
              Read More..
            </a>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardBorder}>
            <div>
              <i></i>
              <h5 className={styles.headingSub}>
                {" "}
                <BubbleChartIcon
                  style={{
                    color: "#01b4cb",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                />{" "}
                User Experience/User Interface
              </h5>
            </div>
            <p className={styles.pagetextleft}>
              User Experience/User Interface (UX/UI) is a newer field of
              practice based on an age-old business principle: The customer is
              always right. Our job is to identify the ways we can help you
              achieve your objectives. We look to provide options that are
              supported by real user data, so decisions are made in...
            </p>
            <a href="/services#uiux-design" className={styles.btnlink}>
              Read More..
            </a>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardBorder}>
            <div>
              <i></i>
              <h5 className={styles.headingSub}>
                {" "}
                <GitHubIcon
                  style={{
                    color: "#01b4cb",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                />{" "}
                Cloud DevOps
              </h5>
            </div>
            <p className={styles.pagetextleft}>
              Cloud has the potential to streamline operational overhead that
              typically taxes on-premise solutions. Our cloud services approach
              is customized to meet the specific needs of our clients and
              address the solution architecture and technical requirements
              associated with each project. We bring clients...
            </p>
            <a href="/services#cloud-devops" className={styles.btnlink}>
              Read More..
            </a>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardBorder}>
            <div>
              <i></i>
              <h5 className={styles.headingSub}>
                {" "}
                <BusinessCenterIcon
                  style={{
                    color: "#01b4cb",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                />{" "}
                Enterprise Architecture
              </h5>
            </div>
            <p className={styles.pagetextleft}>
              Enterprise Architecture (EA) and its subdisciplines provide the
              foundation for strategic planning and to develop roadmaps to guide
              implementation efforts. Having an enterprise architecture based on
              your organization’s business and technical goals supports informed
              investments and management of your...
            </p>
            <a href="/services#enterprise-architect" className={styles.btnlink}>
              Read More..
            </a>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardBorder}>
            <div>
              <i></i>
              <h5 className={styles.headingSub}>
                {" "}
                <SecurityIcon
                  style={{
                    color: "#01b4cb",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                />{" "}
                Information Security
              </h5>
            </div>
            <p className={styles.pagetextleft}>
              Interconnectivity and constituent facing services improve the
              efficiency of service delivery and unfortunately, also increases
              the number of types of information security threats faced by
              government entities. Safeguarding sensitive data and maintaining
              the trust of constituents requires a comprehens...
            </p>
            <a href="/services#information-security" className={styles.btnlink}>
              Read More..
            </a>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardBorder}>
            <div>
              <i></i>
              <h5 className={styles.headingSub}>
                {" "}
                <CachedIcon
                  style={{
                    color: "#01b4cb",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                />{" "}
                System Integration
              </h5>
            </div>
            <p className={styles.pagetextleft}>
              Software development rarely occurs in a vacuum and requires
              reliable and timely coordination across project teams and extend
              into the organization’s ecosystem. Bringing elements like
              interfaces, data conversion, and training together requires system
              integration experience that spans from project ini...
            </p>

            <a href="/services#system-integration" className={styles.btnlink}>
              Read More..
            </a>
          </Card>
        </Grid> */}
        </Grid>
      </Box>

      {/* Corporate section */}
      {/* <div className={classes.corporate}>
        <h1 className={styles.corporate}>Corporate Services</h1> */}
      <div>
        <h1 className={styles.centerText}>Corporate Services</h1>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} md={4}>
            <Card className={classes.cardBorder}>
              <div>
                <i></i>
                <h5 className={styles.headingSub}>
                  {" "}
                  <CachedIcon
                    style={{
                      color: "#01b4cb",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  />{" "}
                  Software Development
                </h5>
              </div>
              <p className={styles.pagetextleft}>
                We use agile practices because they deliver value quickly and
                establish the type of collaboration we want to have with
                clients. Short feedback loops, incremental evidence of progress,
                and efficiency are some of the reasons we look for opportunities
                to apply agile development in new settings, applica...
              </p>

              <a
                href="/services#agile-software-development"
                className={styles.btnlink}
              >
                Read More..
              </a>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.cardBorder}>
              <div>
                <i></i>
                <h5 className={styles.headingSub}>
                  {" "}
                  <BubbleChartIcon
                    style={{
                      color: "#01b4cb",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  />{" "}
                  User Experience/User Interface
                </h5>
              </div>
              <p className={styles.pagetextleft}>
                User Experience/User Interface (UX/UI) is a newer field of
                practice based on an age-old business principle: The customer is
                always right. Our job is to identify the ways we can help you
                achieve your objectives. We look to provide options that are
                supported by real user data, so decisions are made in...
              </p>
              <a href="/services#uiux-design" className={styles.btnlink}>
                Read More..
              </a>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.cardBorder}>
              <div>
                <i></i>
                <h5 className={styles.headingSub}>
                  {" "}
                  <GitHubIcon
                    style={{
                      color: "#01b4cb",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  />{" "}
                  Cloud DevOps
                </h5>
              </div>
              <p className={styles.pagetextleft}>
                Cloud has the potential to streamline operational overhead that
                typically taxes on-premise solutions. Our cloud services
                approach is customized to meet the specific needs of our clients
                and address the solution architecture and technical requirements
                associated with each project. We bring clients...
              </p>
              <a href="/services#cloud-devops" className={styles.btnlink}>
                Read More..
              </a>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.cardBorder}>
              <div>
                <i></i>
                <h5 className={styles.headingSub}>
                  {" "}
                  <BusinessCenterIcon
                    style={{
                      color: "#01b4cb",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  />{" "}
                  Enterprise Architecture
                </h5>
              </div>
              <p className={styles.pagetextleft}>
                Enterprise Architecture (EA) and its subdisciplines provide the
                foundation for strategic planning and to develop roadmaps to
                guide implementation efforts. Having an enterprise architecture
                based on your organization’s business and technical goals
                supports informed investments and management of your...
              </p>
              <a
                href="/services#enterprise-architect"
                className={styles.btnlink}
              >
                Read More..
              </a>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.cardBorder}>
              <div>
                <i></i>
                <h5 className={styles.headingSub}>
                  {" "}
                  <SecurityIcon
                    style={{
                      color: "#01b4cb",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  />{" "}
                  Information Security
                </h5>
              </div>
              <p className={styles.pagetextleft}>
                Interconnectivity and constituent facing services improve the
                efficiency of service delivery and unfortunately, also increases
                the number of types of information security threats faced by
                government entities. Safeguarding sensitive data and maintaining
                the trust of constituents requires a comprehens...
              </p>
              <a
                href="/services#information-security"
                className={styles.btnlink}
              >
                Read More..
              </a>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.cardBorder}>
              <div>
                <i></i>
                <h5 className={styles.headingSub}>
                  {" "}
                  <CachedIcon
                    style={{
                      color: "#01b4cb",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  />{" "}
                  System Integration
                </h5>
              </div>
              <p className={styles.pagetextleft}>
                Software development rarely occurs in a vacuum and requires
                reliable and timely coordination across project teams and extend
                into the organization’s ecosystem. Bringing elements like
                interfaces, data conversion, and training together requires
                system integration experience that spans from project ini...
              </p>

              <a href="/services#system-integration" className={styles.btnlink}>
                Read More..
              </a>
            </Card>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default Home;
