import React from "react";
import Header from "./ui/Header";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./ui/Theme";
import Footer from "../components/ui/Footer";
import Home from "./Home/Home";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Services from "./Services/Services";
import Community from "./Community/Community";
import About from "./About/About";
import Contact from "./Contact/Contact";
import "./App.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/community" component={Community} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
        <Footer />
      </BrowserRouter>
      <MessengerCustomerChat
        pageId="111275418312939"
        appId="1032895117430551"
      />
    </ThemeProvider>
  );
}

export default App;
