import { createMuiTheme } from "@material-ui/core/styles";

const nebBlue = "#0e2c58";
//#1d1c1c
const nebGrey = "#073c54";
const nebGreen = "#07b8b8";
const nebDarkBlue = "#1A3352";
//const underCarriage = "#01aee3";
//#0e2c58
//#1d1c1c
const theme = createMuiTheme({
  palette: {
    common: {
      nebBlue: `${nebBlue}`,
      nebGrey: `${nebGrey}`,
      blue: `${nebGrey}`,
      nebGreen: `${nebGreen}`,
      nebDarkBlue: `${nebDarkBlue}`,
    },
    primary: {
      main: `${nebBlue}`,
    },
    secondary: {
      main: `${nebGrey}`,
    },
  },
  typography: {
    h3: {
      fontWeight: 700,
    },
  },
});

export default theme;
