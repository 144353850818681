import React from "react";
import { makeStyles } from "@material-ui/styles";
import Logo from "../../assets/images/community.jpg";
import Card1 from "../../assets/images/card1.jpg";
import Card2 from "../../assets/images/card2.jpg";
import Card3 from "../../assets/images/card3.jpg";
import { Card, CardMedia, Grid } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const Services = () => {
  const useStyles = makeStyles((theme) => ({
    media: {
      height: "450px",
      backgroundPosition: "inherit",
    },
    card1: {
      height: "400px",
      backgroundPosition: "inherit",
    },
    card2: {
      height: "400px",
      backgroundPosition: "bottom",
    },
    card3: {
      height: "400px",
      backgroundPosition: "center",
    },
    // cardBorder: {
    //   border: "none",
    //   boxShadow: "none",
    // },
    whatwedo: {
      display: "flex",
      height: "38rem",
      width: "43rem",
    },
    systemIntegration: {
      display: "flex",
      height: "58rem",
      width: "43rem",
    },
    security: {
      display: "flex",
      height: "32rem",
      width: "29rem",
    },
    overlay: {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "#00000085",
    },
    text: {
      color: "white",
      paddingTop: "6em",
      fontFamily: "sans-serif",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
    },
    topLine: {
      fontSize: "30px",
      height: ".5rem",
      paddingTop: "50px",
    },
    agileSoftDev: {
      marginTop: "100px",
      height: "70rem",
      paddingLeft: "10rem",
      border: "none",
      boxShadow: "none",
    },
    paragraph: {
      fontSize: "20px",
      lineHeight: "2rem",
    },
    card: {
      position: "relative",
      border: "none",
      boxShadow: "none",
    },
    header: {
      fontSize: "35px",
      fontWeight: "bold",
    },
    sysIntegration: {
      border: "none",
      boxShadow: "none",
      backgroundColor: "grey",
      color: "grey",
    },
    userEx: {
      border: "none",
      boxShadow: "none",
    },
    cloudDevOps: {
      border: "none",
      boxShadow: "none",
    },
    infoSecurity: {
      border: "none",
      boxShadow: "none",
    },
    gridParent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    gridContainer: {
      paddingLeft: "2em",
      paddingRight: "2em",
      paddingTop: ".5em",
      paddingBottom: "100px",
      width: "100% !important",
      margin: "0 !important",
    },
    enterpriseArchitecture: {
      border: "none",
      boxShadow: "none",
    },
  }));
  const classes = useStyles();

  return (
    <div style={{ minHeight: "100vh" }}>
      <Card className={classes.card}>
        <CardMedia image={Logo} className={classes.media} />
        <div className={classes.overlay}>
          <div className={classes.text}>
            <div className={classes.topLine}>
              <h2>Community</h2>
            </div>
          </div>
        </div>
      </Card>

      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardBorder}>
            <CardActionArea>
              <CardMedia
                className={classes.card1}
                image={Card1}
                title="Texas LongHorn"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Environment
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  We are proud Texans and as such we value our home and
                  freedoms! Lets us help you on your next campaign to build your
                  dream website or simply ask us to see where we can help.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className={classes.cardBorder}>
            <CardActionArea>
              <CardMedia
                className={classes.card2}
                image={Card2}
                title="Education"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Education
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Here at NebuCorp we believe Education is important, thats why
                  we are always looking to give back to the community. We are
                  involved and have halped with events such as BubbleChart
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className={classes.cardBorder}>
            <CardActionArea>
              <CardMedia
                className={classes.card3}
                image={Card3}
                title="Software"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Software Development
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Our team is always up to date with the newest technologies. We
                  are ready and able to take on your next project. Just reach
                  out to use by clicking the learn more button or via email at
                  information@nebucorp.com
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
export default Services;
